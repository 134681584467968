







































import { Notify } from "vant"
import ScrollX from "@/components/ShareComponent/ScrollViewX.vue"
import Stroage from "@/util/Storage"
import { HandTypeT } from "@/Type/index"
import { HandStore } from "@/store/modules/Hand/Hand"
import { HandType } from "@/Api/Hand/index"
import { Vue,Component, Watch, Emit } from "vue-property-decorator"

interface HandNavType<T=string>{
    loadingShow:boolean
    userId:T|number;
    token:T;
    NavList:Array<HandTypeT>
    NavIndex:number
    DownIcon:T;
    Show:boolean;

    initData():void;
    handleChangeShow():void;
    handleClickType(data:HandTypeT,index:number,sel?:boolean):void;
    handleUpData():void;
    handleError(data:string):void;
}

@Component({ name:"HandNav",components:{ ScrollX } })
export default class HandNav extends Vue implements HandNavType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    NavList:Array<HandTypeT> = [ { name:"全部", } ]
    NavIndex = 0
    DownIcon = require("$icon/Basics/Down.png")
    Show = false

    mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")  
       this.initData()
    }

    initData(){
        if( HandStore.GetHandTypeList.length ){
            this.NavList = this.NavList.concat(HandStore.GetHandTypeList)
        }else{
           this.handleUpData()
        }

        let UpData = HandStore.GetHandleAllUpData
        if( "categoryId" in UpData ){
            if( this.NavList.length ){
                let Index = this.NavList.findIndex( a=>a.id == UpData.categoryId )
                if( Index > 0 ){
                    this.NavIndex = Index
                }
            }
        }
    }

    handleChangeShow(){
        this.Show = !this.Show
    }

    handleClickType(data:HandTypeT,index:number,sel:boolean){
        if( index != this.NavIndex ){
            this.NavIndex = index
            this.PullSelType( data )
            if( sel ){
                this.handleChangeShow()
            }
        }
    }

    handleUpData(){
        this.loadingShow = true
        HandType({
            userId:this.userId,
            token:this.token
        }).then(res=>{
            this.loadingShow = false
            if( res.message.code === "200" ){
                this.NavList = this.NavList.concat(res.data)
                let UpData = HandStore.GetHandleAllUpData
               if( "categoryId" in UpData ){
                   if( this.NavList.length ){
                       let Index = this.NavList.findIndex( a=>a.id == UpData.categoryId )
                       if( Index > 0 ){
                           this.NavIndex = Index
                       }
                   }
               }
                HandStore.SetHandTypeList( res.data )
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    @Watch("Show")
    ChangeShow(newVal:boolean){
        if( newVal ){
            document.body.style.overflow = "hidden"
        }else{
            document.body.style.overflow = "auto"
        }
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
   }

   @Emit("PullSelType")
   PullSelType(data:HandTypeT){
       return data
   }
}
