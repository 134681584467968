
























import { Vue,Component,Ref, Watch, Emit } from "vue-property-decorator"

interface HandSearchType<T=string>{
    loadingShow:boolean
    UserIcon:T;
    SearchIcon:T;
    SearchVal:T;
    InputShow:boolean

    handleShowInput():void;
    handleShowClose():void;
    handleUpSerchBtn(e:Event):void;
    handleClickUser():void;
}

@Component({ name:"HandSearch", })
export default class HandSearch extends Vue implements HandSearchType{
    loadingShow = false
    UserIcon = require("$icon/Hand/User.png");
    SearchIcon = require("$icon/Basics/search.png")
    SearchVal = ""
    InputShow = false

    handleShowClose(): void {
       let reg = / /g
       this.SearchVal = this.SearchVal.replace(reg,"")
       if( this.SearchVal.length <= 0 ){ this.InputShow = false }
    }

    handleShowInput(){
        this.InputShow = true
        this.GetInput.focus() 
    }

    handleUpSerchBtn(e: Event): void {
       let reg = / /g
       this.SearchVal = this.SearchVal.replace(reg,"")
       if( this.SearchVal.length ){
           this.loadingShow = true
           this.PullSearchData( this.SearchVal )
       }
    }

    handleClickUser(){
        this.$router.push({
            path:"/handUser"
        })
    }

    @Ref("serch") 
    input!:HTMLElement;

    get GetInput(){
        return this.input
    }

    @Watch("SearchVal")
    ChangeSearchVal(newVal:string){
        if( newVal.length <= 0 ){
            this.PullSearchData("")
        }
    }

    @Emit("PullSearchData")
    PullSearchData(data:string){
        return data
    }
}
