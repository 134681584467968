



















































import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import HandSearch from "@/components/HandComponent/AllHand/HandSearch.vue"
import HandNav from "@/components/HandComponent/AllHand/HandNav.vue"
import List from "@/components/ShareComponent/PullDownUp.vue"
import AllHandItem from "@/components/HandComponent/AllHandComponent.vue"
import FooterBottom from "@/components/ShareComponent/ListBottom.vue"
import Stroage from "@/util/Storage"
import { HandTypeT,HandAllItem } from "@/Type/index"
import { HandAllUpData } from "@/Api/Hand/interfaceType"
import { HandStore } from "@/store/modules/Hand/Hand"
import { HandUpAll } from "@/Api/Hand/index"
import { Vue,Component, Watch } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface AllHandType<T=string>{
    loadingShow:boolean
    userId:T|number;
    token:T;
    IssueIcon:T;
    List:Array<HandAllItem>
    UpData:HandAllUpData
    StopUp:boolean;
    PullDown:boolean;
    UpDown:boolean;
    ScrollNum:number

    initData():void;
    handleSearchData(data:string):void;
    handleSelType(data:HandTypeT):void
    handlePullDown(data?:any):void;
    handleUpDown(data?:any):void;
    handleUpData(bool?:boolean):void;
    handleToPath():void;
    handleError(data:string):void;
}

@Component({ name:"AllHand",components:{ HandSearch,HandNav,List,AllHandItem,FooterBottom,HeadTop } })
export default class AllHand extends ZoomPage implements AllHandType{
    loadingShow = true
    userId:string|number = ""
    token = ""
    IssueIcon = require("$icon/Hand/write.png")
    List:Array<HandAllItem> = []
    UpData:HandAllUpData = {
        pageNo:1,
        pageSize:15
    }
    StopUp = false;
    PullDown = false;
    UpDown = true;
    ScrollNum = 0

    mounted() {
       this.loadingShow = false
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       this.initData()
    }

    initData(){
        let UpData = HandStore.GetHandleAllUpData
        if( !( "status" in UpData || "title" in UpData ) ){
            this.UpData = UpData
            if( HandStore.GetHandAllList.length ){
               this.List = HandStore.GetHandAllList
               this.UpDown = false
               let time = setTimeout(()=>{
                   this.ScrollNum = HandStore.GetHandScroll.ALLScrollNum
                   clearTimeout( time )
               },100)
           }else{
               this.handleUpData(true)
           }
        }else{
            this.handleUpData(true)
        }
    }

    handleSearchData(data:string){
        if( data.length ){
            // 搜索内容
            this.UpData = {
                pageNo : 1,
                pageSize : 15
            }
            let reg = /\d+/g
            if( reg.test( data as string ) ){
                this.UpData.status = data
            }
            this.UpData.title = data
            this.handleUpData(true)
        }else{
            this.UpData = { pageNo:1,pageSize:15 }
            this.handleUpData(true)
            // 默认数据
        }
    }

    handleSelType(data:HandTypeT){
        if( /全部/g.test( data.name as string ) ){
            this.UpData = {
                pageNo:1,
                pageSize:15
            }
        }else{
            this.UpData = {
                pageNo:1,
                pageSize:15
            }
            this.UpData.categoryId = data.id as string
        }
        this.handleUpData( true )
    }

    handlePullDown(data?:any){
       this.PullDown = false
       this.StopUp = false
       this.UpData.pageNo = 1
       HandUpAll({
           userId:this.userId,
            token:this.token
       },this.UpData).then(res=>{
           this.PullDown = true
           if( res.message.code == "200" ){
               if( res.data.smProductList.length < this.UpData.pageSize ){
                   this.StopUp = true
               }
               this.List = res.data.smProductList
           }else{
               this.handleError( res.message.msg )
           }
       })
    }

    handleUpDown(data?:any){
       if( this.StopUp )return;
       this.UpData.pageNo += 1
       this.handleUpData()
    }

    handleUpData(bool?:boolean){
       this.UpDown = true
       HandUpAll({
           userId:this.userId,
            token:this.token
       },this.UpData).then(res=>{
           this.loadingShow = false
           this.UpDown = false
           if( res.message.code === "200" ){
               if( res.data.smProductList.length < this.UpData.pageSize ){
                   this.StopUp = true
               }
               if( bool ){
                   this.List = res.data.smProductList
               }else{
                   this.List = this.List.concat( res.data.smProductList )
               }
           }else{
               this.handleError( res.message.msg )
           }
       })
    }


    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

    handleToPath(){
        this.$router.push({
            path:"/issueHand"
        })
    }

    @Watch("List")
    ChangeList(newVal:Array<any>){
        HandStore.SetHandAllList( newVal )
    }

    @Watch("UpData",{ deep:true })
    ChangeUpData(newVal:HandAllUpData){
        HandStore.SetHandAllUpData( newVal )
    }
}
